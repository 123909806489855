import React from "react";

const ContactMap = () => {
  return (
    <div className="map_section">
      <div id="map">
        <iframe
          title="Dew Pond google location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14182.262542429618!2d72.94703202007909!3d19.18472518564876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b902ff48f9fd%3A0xea7d3a1ca046bb33!2sWagle%20Estate%2C%20Shivaji%20Nagar%2C%20Kisan%20Nagar%201%2C%20Thane%20West%2C%20Thane%2C%20Maharashtra%20400080!5e0!3m2!1sen!2sin!4v1670660187231!5m2!1sen!2sin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMap;
